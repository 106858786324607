import axios from '../../axios';

export const state = {
    subspecialities: []
}

export const mutations = {
    GET_SPECIALITIES(state, subspecialities) {
        state.subspecialities = subspecialities
    },
    EMPTY_SPECIALITIES(state) {
        state.subspecialities = []
    }
}

export const getters = {}

export const actions = {

    getAllSubSpecialities({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.get('/sub_speciality/' + id).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                commit('EMPTY_SPECIALITIES');
                reject(error.response)
            });
        })
    },

    addSubSpeciality({commit}, {title, speciality_id}) {
        return new Promise((resolve, reject) => {
            let obj = {speciality_id: speciality_id, title: title};
            axios.post('/add/sub_speciality', obj).then((response) => {
                commit('GET_SPECIALITIES', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    updateSubSpeciality({commit}, {id, title, speciality_id}) {
        return new Promise((resolve, reject) => {
            let obj = {title: title, speciality_id: speciality_id};
            axios.patch('/sub_speciality/' + id, obj).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    deleteSubSpeciality({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/sub_speciality/' + id).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    }
}
