import axios from '../../axios';

export const state = {
  currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
  profileStatus : []
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  UPDATE_PROFILE(state, profileStatus) {
    state.profileStatus = profileStatus
  }
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    console.log("state", state);
    return !!state.currentUser
  },
  loggedInData(state) {
    console.log("state", state);
    return state
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')



    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars

      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      let data = {
        "email": email,
        "password": password,
        "devices": {
          "udid": "171F268C-4C03-4D89-816E-4FD511568140",
          "token": "e0glvap9nUaupe_7- eKFtF:APA91bEv8Vled8HypKijQ0b0kvV1n9c5Uym5Z3_OCmTM9qBzBTtxfgP1WmAtoPHiy8n7yO2lIzyGhPWJrY 3i2XRdHiItv_6LvANtbApVu3UfdzbwUnDQw6bB7ccdeTJohMI2HOvMIVJd",
          "device": "web"
        }
      }

      axios.post('/auth/login', data).then(function (response) {
        commit('SET_CURRENT_USER', response.data.data)
        localStorage.setItem("auth_token", response.data.data.access_token);
        resolve(response.data.data);
      }).catch((error)=>{
        reject(error)
      });
    });
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit('SET_CURRENT_USER', null);
    localStorage.removeItem('auth_token');
    return new Promise((resolve) => {
      resolve(true);
    });
  },

  updatedProfile({commit},{file,name}) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('image',file);
      formData.append('name',name);
      axios.post('/profile_update', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
        }).then((response) => {
          commit('SET_CURRENT_USER',response.data.data);
          resolve(response.data);
        }).catch((error)=>{
            reject(error.response)
        });
    })
  },

  updatedPassword({commit},{old_password,new_password}) {
    return new Promise((resolve, reject) => {
      let obj = {old_password:old_password,new_password:new_password};
      axios.post('/change_password',obj).then((response) => {
          commit('UPDATE_PROFILE',response.data.data);
          resolve(response.data);
        }).catch((error)=>{
            reject(error.response)
        });
    })
  },

  passwordResetEmail({commit}, {email}) {
    return new Promise((resolve, reject) => {
      let obj = {email: email};
      axios.post('/admin/forgot_password', obj).then(function (response) {
        commit('');
        resolve(response.data.data);
      }).catch((error) => {
        reject(error)
      });
    });
  },

  passwordReset({commit}, {password,code}) {
    return new Promise((resolve, reject) => {
      let obj = {password: password , code : code};
      axios.post('/auth/verify/code', obj).then(function (response) {
        commit('');
        resolve(response.data.data);
      }).catch((error) => {
        reject(error)
      });
    });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  // register({ commit, dispatch, getters }, { fullname, email, password } = {}) {
  //   if (getters.loggedIn) return dispatch('validate')
  //
  //   return getFirebaseBackend().registerUser(email, password).then((response) => {
  //     const user = response
  //     commit('SET_CURRENT_USER', user)
  //     return user
  //   });
  // },

  // register the user
  // eslint-disable-next-line no-unused-vars
  // resetPassword({ commit, dispatch, getters }, { email } = {}) {
  //   if (getters.loggedIn) return dispatch('validate')
  //
  //   return getFirebaseBackend().forgetPassword(email).then((response) => {
  //     const message = response.data
  //     return message
  //   });
  // },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser)
    {
      return Promise.resolve(null)
    }

    return Promise.resolve(state.currentUser);
  },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
