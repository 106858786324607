import axios from '../../axios';

export const state = {
  employers : [],
  status: [],
  pagination: {}
}

export const mutations = {
  GET_EMPLOYERS(state, employers) {
    state.employers = employers
  },
  GET_STATUS(state, status) {
    state.status = status
  },
  GET_PAGINATION(state, pagination) {
    state.pagination = pagination
  },
  DELETE_EMPLOYER(state, data) {
      let index = state.employers.findIndex(x => x.id === data.id)
      if (index !== -1) {
        state.employers.splice(index, 1)
      }
  },
}

export const getters = {

}

export const actions = {
  getAllEmployers({commit},{page:page}) {
    return new Promise((resolve, reject) => {
      axios.get('/employers'+'?page='+page).then((response) => {
        commit('GET_PAGINATION',response.data.meta);      
        commit('GET_EMPLOYERS',response.data.data);
        resolve(response.data);
      }).catch((error)=>{
          reject(error.response)
      });
    })
  },

  toggleStatus({commit},{id,status}) {
    return new Promise((resolve, reject) => {
      axios.get('/status/'+id+'/'+status).then((response) => {
          commit('GET_STATUS',response.data.data);
          resolve(response.data);
        }).catch((error)=>{
            reject(error.response)
        });
    })
  },

  deleteEmployerUser({commit}, {id}) {
    return new Promise((resolve, reject) => {
      axios.delete('/employer/delete/' + id).then((response) => {
        commit('DELETE_EMPLOYER', response.data.data);
        resolve(response.data);
      }).catch((error) => {
        reject(error.response)
      });
    })
  },

}
