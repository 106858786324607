import axios from '../../axios';

export const state = {
    jobs: [],
    pagination: {},
}

export const mutations = {
    GET_ALL_JOBS(state, jobs) {
        state.jobs = jobs
    },
    GET_PAGINATION(state, pagination) {
        state.pagination = pagination
    },
    CANCEL_JOB(state,data){
        let index = state.jobs.findIndex(x => x.id === data.id)
        if (index !== -1) {
            state.jobs[index].status = data.status
            state.jobs[index].cancel_by = data.cancel_by
            state.jobs[index].job_request.map(y=>{
                y.status = data.status
                y.cancel_by = data.cancel_by
            });
        }
    }
}

export const getters = {}

export const actions = {
    getAllJobs({commit}, {page: page}) {
        return new Promise((resolve, reject) => {
            axios.get('/jobs' + "?page=" + page).then((response) => {
                commit('GET_PAGINATION', response.data.meta);
                commit('GET_ALL_JOBS', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    cancelEmployerJob({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.patch('/employer/cancel/job/' + id).then((response) => {
                commit('CANCEL_JOB', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
}
