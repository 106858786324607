import axios from "axios";

const instance = axios.create({
    // baseURL: 'http://192.168.18.37:8000/api',
    // baseURL: 'http://localhost:8000/api/v1',
    //     baseURL: 'http://127.0.0.1:8000/api/v1',
    baseURL: 'https://mashghol.com/anyapis/api/v1',
    // baseURL: 'https://anytimework.co/api/v1',
    headers: {
        Authorization: {
            toString () {
                return `Bearer ${localStorage.getItem('auth_token')}`
            }
        }
    }
});

export default instance;
