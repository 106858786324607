import axios from '../../axios';

export const state = {
  industries : []
}

export const mutations = {
  GET_INDUSTRIES(state, industries) {
    state.industries = industries
  }
}

export const getters = {

}

export const actions = {

  getAllIndustries({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/industry').then((response) => {
        commit('GET_INDUSTRIES',response.data.data);
          resolve(response.data);
      }).catch((error)=>{
        reject(error.response)
      });
    })
  },

  addIndustry({commit},{title}) {
    return new Promise((resolve, reject) => {
      let obj = {title:title};
      axios.post('/add/industry',obj).then((response) => {
        commit('GET_INDUSTRIES',response.data)
        resolve(response.data);
        }).catch((error)=>{
          reject(error.response)
      });
  })
  },

  updateIndustry({commit},{id,title}) {
    return new Promise((resolve, reject) => {
      let obj ={title:title};
      axios.patch('/industry/'+id,obj).then((response) => {
        commit('GET_INDUSTRIES',response.data);
        resolve(response.data);
        }).catch((error)=>{
          reject(error.response)
        });
    })
  },

   deleteIndustry({commit},{id}) {
    return new Promise((resolve, reject) => {
      axios.delete('/industry/'+id).then((response) => {
      commit('GET_INDUSTRIES',response.data);
      resolve(response.data);
      }).catch((error)=>{
        reject(error.response)
      });
    })
  }
}
