import store from '@/state/store'
import router from "./index";

export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/dashboard/home'),
        // eslint-disable-next-line no-unused-vars
        // beforeEnter: (to, from, next) => {
        //         console.log("store.getters['auth/loggedIn']",store.getters['auth/loggedIn']);
        // },
        meta: {
            authRequired: true,
            // beforeResolve(routeTo, routeFrom, next) {
            //     // If the user is already logged in
            //     console.log("store.getters['auth/loggedIn']",store.getters['auth/loggedIn']);
            //
            //     if (store.getters['auth/loggedIn']) {
            //         // Redirect to the home page instead
            //         next({ name: 'dashboard' })
            //     } else {
            //         // Continue to the login page
            //         next({ name: 'login' })
            //     }
            // },
        }
    },
    {
        path: '/employee',
        name: 'employee',
        component: () => import('./views/admin/employee'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/employer',
        name: 'employer',
        component: () => import('./views/admin/employer'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/employee/blocked',
        name: 'blocked_employee',
        component: () => import('./views/admin/blockedEmployee'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/employee/verified',
        name: 'verified_employee',
        component: () => import('./views/admin/verifiedEmployee'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/employee/not/verified',
        name: 'not_verified_employee',
        component: () => import('./views/admin/notVerifiedEmployee'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/employer/detail',
        name: 'employer_detail',
        component: () => import('./views/admin/userDetail'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('./views/user/settings'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: () => import('./views/jobs/all'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/job_details',
        name: 'job_details',
        component: () => import('./views/jobs/detail'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/industries',
        name : 'industries',
        component: () => import('./views/settings/industries'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/services',
        component: () => import('./views/settings/services'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/fo_service',
        name: 'fo_service',
        component: () => import('./views/settings/fo_services'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/speciality',
        name: 'speciality',
        component: () => import('./views/settings/specialities'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/subspeciality',
        name: 'subspeciality',
        component: () => import('./views/settings/sub_specialities'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('./views/app_settings/all_settings'),
        meta: {
            authRequired: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'dashboard' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./views/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password',
        name: 'reset_password',
        component: () => import('./views/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut').then(
                    router.push('/login')
                )
                next();
                // const authRequiredOnPreviousRoute = routeFrom.matched.some(
                //     (route) => route.push('/login')
                // )
                // // Navigate back to previous page, or home as a fallback
                // next(authRequiredOnPreviousRoute ? { name: 'employee' } : { ...routeFrom })
            },
        },
    },


]
