import axios from '../../axios';

export const state = {
    specialities: []
}

export const mutations = {
    GET_SPECIALITIES(state, specialities) {
        state.specialities = specialities
    },
    EMPTY_SPECIALITIES(state, ) {
        state.specialities = []
    }

}

export const getters = {}

export const actions = {

    getAllSpecialities({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.get('/speciality/' + id).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                commit('EMPTY_SPECIALITIES');
                reject(error.response)
            });
        })
    },

    addSpeciality({commit}, {id, title, fo_service_id}) {
        return new Promise((resolve, reject) => {
            let obj = {id: id, title: title, fo_service_id: fo_service_id};
            axios.post('/add/speciality', obj).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    updateSpeciality({commit}, {id, title, fo_service_id}) {
        return new Promise((resolve, reject) => {
            let obj = {title: title, fo_service_id: fo_service_id};
            axios.patch('/speciality/' + id, obj).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    deleteSpeciality({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/speciality/' + id).then((response) => {
                commit('GET_SPECIALITIES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
}
