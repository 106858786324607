import axios from '../../axios';

export const state = {
    services: []
}

export const mutations = {
    GET_SERVICES(state, services) {
        state.services = services
    }
}

export const getters = {}

export const actions = {

    getAllServices({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/service').then((response) => {
                commit('GET_SERVICES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    addService({commit}, {title, hourly_rate}) {
        return new Promise((resolve, reject) => {
            let obj = {title: title, hourly_rate: hourly_rate};
            axios.post('/add/service', obj).then((response) => {
                commit('GET_SERVICES', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    updateService({commit}, {id, title, hourly_rate}) {
        return new Promise((resolve, reject) => {
            let obj = {title: title, hourly_rate: hourly_rate};
            axios.patch('/service/' + id, obj).then((response) => {
                commit('GET_SERVICES', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    deleteService({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/service/' + id).then((response) => {
                commit('GET_SERVICES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    }
}
