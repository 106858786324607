import axios from '../../axios';

export const state = {
    employees: [],
    status: [],
    pagination: {}
}

export const mutations = {
    GET_EMPLOYEES(state, employees) {
        state.employees = employees
    },
    GET_STATUS(state, status) {
        state.status = status
    },
    UPDATE_DOC_STATUS(state, data) {
        state.employees.map(x => {
            let index = x.documents.findIndex(document => document.id === data.id)
            if (index !== -1) {
                x.is_docs = data.is_docs
                x.documents[index].status = data.status
            }
        })
    },

    UPDATE_SKILL_STATUS(state, data) {
        state.employees.map(x => {
            let index = x.skills.findIndex(skill => skill.id === data.id)
            if (index !== -1) {
                x.skills[index].status = data.status
            }
        })
    },
    GET_PAGINATION(state, pagination) {
        state.pagination = pagination
    },
    DELETE_DOC(state, data) {
        state.employees.map(x => {
            let index = x.documents.findIndex(document => document.id === data.id)
            console.log(data, index)
            if (index !== -1) {
                x.documents.splice(index, 1)
            }
        })
    },
    DELETE_SKILL(state, data) {
        state.employees.map(x => {
            let index = x.skills.findIndex(skill => skill.id === data.id)
            console.log(data, index)
            if (index !== -1) {
                x.skills.splice(index, 1)
            }
        })
    },
    DELETE_EMPLOYEE(state, data) {
        let index = state.employees.findIndex(x => x.id === data.id)
        if (index !== -1) {
            state.employees.splice(index, 1)
        }
    },

}

export const getters = {}

export const actions = {
    getAllEmployees({commit}, {page}) {
        return new Promise((resolve, reject) => {
            axios.get('/employees' + '?page=' + page).then((response) => {
                commit('GET_PAGINATION', response.data.meta);
                commit('GET_EMPLOYEES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
    getVerifiedEmployees({commit}, {page}) {
        return new Promise((resolve, reject) => {
            axios.get('/employee/verified' + '?page=' + page).then((response) => {
                commit('GET_PAGINATION', response.data.meta);
                commit('GET_EMPLOYEES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
    getNotVerifiedEmployees({commit}, {page}) {
        return new Promise((resolve, reject) => {
            axios.get('/employee/not/verified' + '?page=' + page).then((response) => {
                commit('GET_PAGINATION', response.data.meta);
                commit('GET_EMPLOYEES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
    getBlockedEmployees({commit}, {page}) {
        return new Promise((resolve, reject) => {
            axios.get('/employee/blocked' + '?page=' + page).then((response) => {
                commit('GET_PAGINATION', response.data.meta);
                commit('GET_EMPLOYEES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },


    toggleStatus({commit}, {id, status}) {
        return new Promise((resolve, reject) => {
            axios.get('/status/' + id + '/' + status).then((response) => {
                commit('GET_STATUS', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    updateStatus({commit}, {data}) {
        return new Promise((resolve, reject) => {
            axios.patch('/admin/docs/status/' + data.id, {'status': data.status}).then((response) => {
                commit('UPDATE_DOC_STATUS', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    updateSkillsStatus({commit}, {data}) {
        return new Promise((resolve, reject) => {
            axios.patch('/admin/skill/status/' + data.id, {'status': data.status}).then((response) => {
                commit('UPDATE_SKILL_STATUS', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    deleteUserSkills({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/admin/skill/' + id).then((response) => {
                commit('DELETE_SKILL', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    deleteUserDocs({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/admin/docs/' + id).then((response) => {
                commit('DELETE_DOC', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
    deleteEmployeeUser({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/employee/delete/' + id).then((response) => {
                commit('DELETE_EMPLOYEE', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
}
