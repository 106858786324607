import axios from '../../axios';

export const state = {
    foservices: []
}

export const mutations = {
    GET_FO_SERVICES(state, foservices) {
        state.foservices = foservices
    },
    EMPTY_FO_SERVICE(state) {
        state.foservices = []
    }
}

export const getters = {}

export const actions = {

    getAllFoServices({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.get('/fo_service/' + id).then((response) => {
                commit('GET_FO_SERVICES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                commit('EMPTY_FO_SERVICE')
                reject(error.response)
            });
        })
    },

    addFoService({commit}, {service_id, title}) {
        return new Promise((resolve, reject) => {
            let obj = {service_id: service_id, title: title};
            axios.post('/add/fo_service', obj).then((response) => {
                commit('GET_FO_SERVICES', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    updateFoService({commit}, {id, title, service_id}) {
        return new Promise((resolve, reject) => {
            let obj = {title: title, service_id: service_id};
            axios.patch('/fo_service/' + id, obj).then((response) => {
                commit('GET_FO_SERVICES', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },

    deleteFoService({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axios.delete('/fo_service/' + id).then((response) => {
                commit('GET_FO_SERVICES', response.data.data);
                resolve(response.data);
            }).catch((error) => {
                reject(error.response)
            });
        })
    },
}
