import axios from '../../axios';


export const state = {
    stats : [],
    graph: []
}

export const mutations = {
  GET_Stats(state, stats) {
    state.stats = stats
  },
  GET_GRAPH_VALUES(state, graph) {
    state.graph = graph
  },
}

export const getters = {

}

export const actions = {

  getAllStats({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/stats').then((response) => {
        commit('GET_Stats',response.data.data);
        resolve(response.data);
      }).catch((error)=>{
        reject(error.response)
      });
    })
  },



  getGraphData({commit},{type}){
    return new Promise((resolve,reject) => {
      axios.get('/graphs/'+type).then((response) => {
        commit('GET_GRAPH_VALUES',response.data.data);
        resolve(response.data);
      }).catch((error)=>{
        reject(error)
      });
    })
  }
}
