import axios from '../../axios';

export const state = {
  settings : []
}

export const mutations = {
  SETTINGS(state, settings) {
    state.settings = settings
  }
}

export const getters = {

}

export const actions = {

  getAllAppSetting({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/app_setting').then((response) => {
        commit('SETTINGS',response.data.data);
        resolve(response.data);
      }).catch((error)=>{
        reject(error.response)
      });
    })
  },

  addAppSetting({commit},{key,value}) {
    return new Promise((resolve, reject) => {
      let obj = {key:key,value:value};
      axios.post('/add/setting',obj).then((response) => {
        commit('SETTINGS',response.data)
        resolve(response.data);
        }).catch((error)=>{
          reject(error.response)
      });
  })
  },

  updateAppSetting({commit},{id,key,value}) {
    return new Promise((resolve, reject) => {
      let obj ={key:key,value:value};
      axios.patch('/setting/'+id,obj).then((response) => {
        commit('SETTINGS',response.data);
        resolve(response.data);
        }).catch((error)=>{
          reject(error.response)
        });
    })
  },

   deleteAppSetting({commit},{id}) {
    return new Promise((resolve, reject) => {
      axios.delete('/setting/'+id).then((response) => {
      commit('SETTINGS',response.data);
      resolve(response.data);
      }).catch((error)=>{
        reject(error.response)
      });
    })
  }
}
